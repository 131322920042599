import * as React from 'react'

function SvgFacebook() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 10 20"
    >
      <path d="M6.25 6.875v-2.5a1.25 1.25 0 011.25-1.25h1.25V0h-2.5A3.75 3.75 0 002.5 3.75v3.125H0V10h2.5v10h3.75V10h2.5L10 6.875z" />
    </svg>
  )
}

const MemoSvgFacebook = React.memo(SvgFacebook)
export default MemoSvgFacebook
