import * as React from 'react'

function SvgMinus() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 4"
    >
      <path d="M0 0h20v4H0z" />
    </svg>
  )
}

const MemoSvgMinus = React.memo(SvgMinus)
export default MemoSvgMinus
