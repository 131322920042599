import * as React from 'react'

function SvgChevronLeft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16.253 26.889"
    >
      <path d="M14.828 1.407c-3 3.032-12 11.9-12 11.9l12 12.178" />
    </svg>
  )
}

const MemoSvgChevronLeft = React.memo(SvgChevronLeft)
export default MemoSvgChevronLeft
