import * as React from 'react'

function SvgChevronRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 15.828 27.657"
    >
      <path d="M2.828 24.828l11-11-11-11" />
    </svg>
  )
}

const MemoSvgChevronRight = React.memo(SvgChevronRight)
export default MemoSvgChevronRight
