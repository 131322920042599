import * as React from 'react'

function SvgEuro() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
    >
      <path d="M0 9.887a.959.959 0 00.958.955H2.4a7.923 7.923 0 0013.317 2.531.955.955 0 00-.671-1.624.947.947 0 00-.671.287A5.724 5.724 0 019.92 14.09a6.069 6.069 0 01-5.42-3.248h5.844a.955.955 0 100-1.91H3.832a5.763 5.763 0 01-.048-.907v-.478H10.3a.955.955 0 100-1.91H4.263a6.191 6.191 0 015.6-3.725 5.724 5.724 0 014.455 2.054.947.947 0 00.671.287.945.945 0 00.671-1.624A7.823 7.823 0 009.86.002a7.96 7.96 0 00-7.617 5.636H.958a.955.955 0 100 1.91h.958v.478a5.763 5.763 0 00.048.907h-.958A.971.971 0 000 9.887z" />
    </svg>
  )
}

const MemoSvgEuro = React.memo(SvgEuro)
export default MemoSvgEuro
