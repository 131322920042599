import * as React from 'react'

function SvgLanguage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
    >
      <path d="M10 0a10 10 0 1010 10A10 10 0 0010 0zm6.918 6h-2.795a13.644 13.644 0 00-1.106-3.4A8.04 8.04 0 0116.918 6zM18 10a7.96 7.96 0 01-.263 2H14.41a22.02 22.02 0 000-4h3.327A7.963 7.963 0 0118 10zm-8 8c-.436 0-1.485-1.395-2.076-4h4.152c-.591 2.605-1.64 4-2.076 4zm-2.4-6a19.673 19.673 0 010-4h4.8a19.693 19.693 0 01.1 2 19.693 19.693 0 01-.1 2zM2 10a7.963 7.963 0 01.263-2H5.59a22.02 22.02 0 000 4H2.263A7.96 7.96 0 012 10zm8-8c.436 0 1.485 1.395 2.076 4H7.924C8.515 3.395 9.564 2 10 2zm-3.017.6A13.644 13.644 0 005.877 6H3.082a8.04 8.04 0 013.901-3.4zM3.082 14h2.795a13.642 13.642 0 001.106 3.4A8.04 8.04 0 013.082 14zm9.934 3.4a13.642 13.642 0 001.107-3.4h2.794a8.04 8.04 0 01-3.9 3.4z" />
    </svg>
  )
}

const MemoSvgLanguage = React.memo(SvgLanguage)
export default MemoSvgLanguage
