import * as React from 'react'

function SvgCheck() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16 12.144"
    >
      <path d="M14.298.001a1.654 1.654 0 00-1.137.5c-2.617 2.623-5.006 5.194-7.519 7.76L2.688 5.892A1.655 1.655 0 00.621 8.476l4.134 3.307a1.654 1.654 0 002.2-.129c2.974-2.98 5.638-5.907 8.544-8.82A1.654 1.654 0 0014.293 0z" />
    </svg>
  )
}

const MemoSvgCheck = React.memo(SvgCheck)
export default MemoSvgCheck
