import * as React from 'react'
import { memo } from 'react'

const SvgCalendar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 15.801 16"
  >
    <path d="M12.88 1.505h-.9V.584a.584.584 0 1 0-1.168 0v.921H4.99V.584a.584.584 0 0 0-1.168 0v.921h-.9A2.921 2.921 0 0 0 0 4.426v8.653A2.921 2.921 0 0 0 2.921 16h9.959a2.921 2.921 0 0 0 2.921-2.921V4.426a2.921 2.921 0 0 0-2.921-2.921ZM2.921 2.673h9.959a1.753 1.753 0 0 1 1.753 1.753v.439H1.168v-.439a1.753 1.753 0 0 1 1.753-1.753Zm9.959 12.158H2.921a1.753 1.753 0 0 1-1.753-1.753V6.034h13.465v7.045a1.753 1.753 0 0 1-1.753 1.753ZM4.186 8.573a.584.584 0 1 1-.826 0 .584.584 0 0 1 .826 0Zm2.752 0a.584.584 0 1 1-.826 0 .584.584 0 0 1 .826 0Zm2.752 0a.584.584 0 1 1-.826 0 .584.584 0 0 1 .826 0Zm2.752 0a.584.584 0 1 1-.826 0 .584.584 0 0 1 .826 0Zm-8.255 2.9a.584.584 0 1 1-.826 0 .584.584 0 0 1 .825-.005Zm2.752 0a.584.584 0 1 1-.826 0 .584.584 0 0 1 .826 0Zm2.752 0a.584.584 0 1 1-.826 0 .584.584 0 0 1 .826 0Z" />
  </svg>
)

const Memo = memo(SvgCalendar)
export default Memo
