import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from 'app/theme/index'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  label: string
  file?: string
  target?: string
  URL?: string
  onClick?: () => void
  className?: string
  noActions?: boolean
  rel?: string
  variant?: Variant
}

export const Button = memo(function Button({
  label = 'Button',
  file,
  target,
  URL,
  onClick,
  className,
  noActions,
  rel,
  variant = 'M',
}: Props) {
  const buttonClassName = `${className ? className : ''}`
  const buttonDOM = <ButtonLabel variant={variant}>{label}</ButtonLabel>
  const EXTERNAL_URL_REGEX = new RegExp('^(https?|ftp|www)://')
  const externalURL = URL && EXTERNAL_URL_REGEX.test(URL)

  return (
    <>
      {file ? (
        <Anchor
          className={buttonClassName}
          href={file}
          target="_blank"
          rel={rel}
          variant={variant}
        >
          {buttonDOM}
        </Anchor>
      ) : (
        <>
          {!externalURL ? (
            onClick != undefined ? (
              <Anchor
                className={buttonClassName}
                onClick={onClick}
                variant={variant}
              >
                {buttonDOM}
              </Anchor>
            ) : noActions ? (
              <Static className={buttonClassName} variant={variant}>
                {buttonDOM}
              </Static>
            ) : (
              <ButtonItem
                to={URL ? URL : '#'}
                className={buttonClassName}
                variant={variant}
              >
                {buttonDOM}
              </ButtonItem>
            )
          ) : (
            <Anchor
              className={buttonClassName}
              href={URL}
              rel="noopener"
              target={target}
              variant={variant}
            >
              {buttonDOM}
            </Anchor>
          )}
        </>
      )}
    </>
  )
})

const AnimationStyle = css`
  &:hover {
    span {
      transform: translateY(-0.5rem);
      &:after {
        right: 0.0625rem;
        transform: translateY(0.5rem);
      }
    }
  }
`

const SmallStyle = css`
  max-width: 280px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 4px solid ${theme.colors.variants.neutralDark2};
  color: ${theme.colors.variants.neutralDark2};
  font-family: ${theme.fontFamily.heading};
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 0.0625rem;
  line-height: 1.5rem;
  overflow: hidden;
  padding: 0.875rem 1.25rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  &:hover {
    color: ${theme.colors.variants.neutralLight3};
    &:before {
      right: 0;
    }
  }
  &:before {
    content: '';
    height: 100%;
    background: ${theme.colors.variants.neutralDark2};
    position: absolute;
    top: 0;
    right: 100%;
    left: 0;
    transition: 0.4s ease-in-out;
  }

  ${AnimationStyle}
`

const WhiteStyle = css`
  ${SmallStyle}

  border-color: ${theme.colors.variants.neutralLight3};
  color: ${theme.colors.variants.neutralLight3};
  &:before {
    display: none;
  }
`

const MediumStyle = css`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.variants.primaryLight1};
  color: ${theme.colors.variants.neutralLight3};
  font-family: ${theme.fontFamily.heading};
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 0.0625rem;
  line-height: 1.5rem;
  overflow: hidden;
  padding: 1.125rem 3.125rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s ease-in-out;

  ${AnimationStyle}
`

const Anchor = styled.a<ContainerProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'S':
        return SmallStyle
      case 'W':
        return WhiteStyle
      case 'M':
        return MediumStyle
    }
  }}
`

const Static = styled.div<ContainerProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'S':
        return SmallStyle
      case 'W':
        return WhiteStyle
      case 'M':
        return MediumStyle
    }
  }}
`

const ButtonItem = styled(Link)<ContainerProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'S':
        return SmallStyle
      case 'W':
        return WhiteStyle
      case 'M':
        return MediumStyle
    }
  }}
`

const ButtonLabel = styled.span<ContainerProps>`
  display: inline-block;
  position: relative;
  transition: 0.3s ease-in-out;
  z-index: 2;
  &:after {
    content: '';
    height: 0.375rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    bottom: -0.125rem;
    right: 100%;
    left: 0;
    transition: 0.3s ease-in-out;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'S' | 'M' | 'W'
