import * as React from 'react'
import { memo } from 'react'

const SvgError = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 15.999 16"
  >
    <path d="M1.46 16a1.549 1.549 0 0 1-1.044-.413 1.463 1.463 0 0 1 0-2.044l.021-.022c.52-.538 1.058-1.058 1.588-1.589l3.286-3.286L5.957 8l-1.1-1.1-3.13-3.13L.416 2.459a1.47 1.47 0 0 1 0-2.044 1.463 1.463 0 0 1 2.043 0l.021.022c.538.52 1.059 1.058 1.589 1.589l3.286 3.285.646.646 1.1-1.1 3.131-3.13L13.543.416a1.47 1.47 0 0 1 2.043 0 1.464 1.464 0 0 1 0 2.044l-.021.022c-.52.538-1.058 1.058-1.589 1.589l-3.285 3.285-.643.643 1.1 1.1 3.13 3.13 1.311 1.31a1.47 1.47 0 0 1 0 2.044 1.341 1.341 0 0 1-1 .416 1.485 1.485 0 0 1-1.045-.416l-.021-.022c-.538-.52-1.059-1.058-1.589-1.589l-3.286-3.285-.643-.643-1.1 1.1-3.131 3.13-1.311 1.311a1.4 1.4 0 0 1-1 .413Z" />
  </svg>
)

const Memo = memo(SvgError)
export default Memo
