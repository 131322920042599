import * as React from 'react'
import { memo } from 'react'

const SvgGift = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
  >
    <path d="M18.094 5.011h-.762a3.573 3.573 0 0 0 .471-1.731A3.263 3.263 0 0 0 16.91.987 3.161 3.161 0 0 0 14.686 0c-2.444 0-3.9 1.936-4.686 3.531C9.215 1.936 7.758 0 5.314 0A3.161 3.161 0 0 0 3.09.987a3.263 3.263 0 0 0-.893 2.293 3.572 3.572 0 0 0 .471 1.731h-.762a1.891 1.891 0 0 0-1.348.567A1.952 1.952 0 0 0 0 6.947v1.822a1.934 1.934 0 0 0 .34 1.115 1.885 1.885 0 0 0 .915.707v6.788a2.641 2.641 0 0 0 .755 1.852 2.558 2.558 0 0 0 1.823.767h12.332a2.558 2.558 0 0 0 1.823-.767 2.641 2.641 0 0 0 .755-1.852v-6.787a1.885 1.885 0 0 0 .915-.707 1.934 1.934 0 0 0 .34-1.115V6.948a1.952 1.952 0 0 0-.558-1.369 1.891 1.891 0 0 0-1.348-.567Zm.336 1.936v1.822a.339.339 0 0 1-.336.342h-7.309V6.606h7.309a.311.311 0 0 1 .245.093.321.321 0 0 1 .091.249Zm-3.744-5.353a1.6 1.6 0 0 1 1.114.52 1.655 1.655 0 0 1 .433 1.165 1.678 1.678 0 0 1-1.547 1.731h-3.61c.493-1.3 1.614-3.417 3.61-3.417ZM3.765 3.28a1.655 1.655 0 0 1 .433-1.165 1.6 1.6 0 0 1 1.114-.52c1.973 0 3.117 2.118 3.61 3.417h-3.61A1.678 1.678 0 0 1 3.765 3.28Zm-2.2 3.667a.321.321 0 0 1 .091-.249.311.311 0 0 1 .245-.093H9.21v2.506H1.901a.339.339 0 0 1-.336-.342ZM3.83 18.405a1 1 0 0 1-.713-.3 1.033 1.033 0 0 1-.3-.725v-6.674h6.39v7.7Zm12.332 0h-5.377v-7.7h6.39v6.674a1.033 1.033 0 0 1-.3.725 1 1 0 0 1-.713.3Z" />
  </svg>
)

const Memo = memo(SvgGift)
export default Memo
