import * as React from 'react'

function SvgPlane() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 30 30"
    >
      <path d="M26.671 3.317a1.079 1.079 0 00-.611-.3 16.749 16.749 0 00-2.36-.123c-1.269.029-2.8.058-3.847 1.231l-.277.309L9.787.086a1.07 1.07 0 00-1.185.223L5.586 3.325a1.071 1.071 0 000 1.51l7.193 7.194-2.6 2.9-7.308-2.086a1.077 1.077 0 00-1.052.268l-1.5 1.5a1.072 1.072 0 000 1.521l5.336 5.336a16.263 16.263 0 00-.376 2.075 1.072 1.072 0 001.175 1.175 20.216 20.216 0 002.08-.37l5.336 5.336a1.073 1.073 0 001.515 0l1.5-1.5a1.07 1.07 0 00.275-1.045l-2.089-7.314 2.9-2.594 7.185 7.186a1.069 1.069 0 001.515 0l3.016-3.016a1.071 1.071 0 00.218-1.191l-4.343-9.775.315-.283c1.176-1.052 1.206-2.589 1.231-3.86a16.644 16.644 0 00-.129-2.366 1.068 1.068 0 00-.312-.614zm-1.707 2.936a5.331 5.331 0 01-.513 2.3l-14.305 12.8a7.9 7.9 0 01-2.484.984 9.3 9.3 0 01.985-2.483l12.8-14.3a5.345 5.345 0 012.3-.513 7.6 7.6 0 011.153.054 7.528 7.528 0 01.061 1.159zm-6.891-.138l-3.863 4.317-6.35-6.348L9.594 2.35zm5.816 5.823l3.764 8.471-1.734 1.734-6.348-6.345zM8.596 16.704L7.05 18.431a4.706 4.706 0 00-.581.827l-3.88-3.88.3-.3zm4.7 4.711l1.628 5.7-.3.3-3.874-3.874a4.726 4.726 0 00.826-.583z" />
    </svg>
  )
}

const MemoSvgPlane = React.memo(SvgPlane)
export default MemoSvgPlane
