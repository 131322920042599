import * as React from 'react'

function SvgYoutube() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 16"
    >
      <path d="M16.16.51A37.7 37.7 0 003.8.51C1.46 1 0 2.51 0 4.48v7c0 2 1.46 3.49 3.84 4A37.47 37.47 0 0010 16a37.775 37.775 0 006.172-.509c2.328-.441 3.828-2 3.828-4v-7C20 2.5 18.5.94 16.16.51zm-2.9 7.94l-5 3a.48.48 0 01-.26.07.57.57 0 01-.25-.06.52.52 0 01-.25-.46V5a.52.52 0 01.25-.44.54.54 0 01.51 0l5 3a.51.51 0 010 .86z" />
    </svg>
  )
}

const MemoSvgYoutube = React.memo(SvgYoutube)
export default MemoSvgYoutube
