import * as React from 'react'

function SvgPlus() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 8.365 8.365"
    >
      <path d="M3.485 0v3.485H0V4.88h3.485v3.485H4.88V4.88h3.485V3.485H4.88V0z" />
    </svg>
  )
}

const MemoSvgPlus = React.memo(SvgPlus)
export default MemoSvgPlus
