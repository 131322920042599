import * as React from 'react'
import { memo } from 'react'

const SvgPhone = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 21.911 20"
  >
    <path d="M.934 20A.936.936 0 0 1 0 19.068a8.955 8.955 0 0 1 8.952-8.952h4.009a8.955 8.955 0 0 1 8.95 8.812v.14a.934.934 0 0 1-.933.932Zm.978-1.864h18.09a7.136 7.136 0 0 0-7.04-6.2H8.953a7.217 7.217 0 0 0-7.042 6.2ZM13.848 5.595a30.723 30.723 0 0 0-3.59-.046h-.047c-.746 0-1.447 0-2.144.046a4.009 4.009 0 0 1-4.011 3.636A4.059 4.059 0 0 1 .001 5.175a3.988 3.988 0 0 1 2.1-3.543c.092-.046.139-.094.232-.094A20.981 20.981 0 0 1 10.212.046c.326 0 .7-.046 1.072-.046a22.363 22.363 0 0 1 8.346 1.538c.046 0 .092.047.187.094a3.988 3.988 0 0 1 2.1 3.543A4.058 4.058 0 0 1 17.86 9.23a4.008 4.008 0 0 1-4.013-3.635Zm-2.379-1.912c1.166 0 2.425.046 3.4.14a.971.971 0 0 1 .606.326.939.939 0 0 1 .186.7c0 .14-.046.232-.046.326a2.189 2.189 0 0 0 2.191 2.191 2.189 2.189 0 0 0 2.195-2.191 2.208 2.208 0 0 0-1.259-2.005c-.049 0-.049-.046-.094-.046a20.9 20.9 0 0 0-7.366-1.306H10.21A19.317 19.317 0 0 0 3.171 3.17c-.047 0-.047.047-.094.047a2.137 2.137 0 0 0-1.259 1.958 2.188 2.188 0 0 0 2.191 2.191A2.188 2.188 0 0 0 6.2 5.175a.9.9 0 0 0-.047-.326.944.944 0 0 1 .186-.7.8.8 0 0 1 .605-.326c1.026-.094 2.1-.14 3.172-.14Z" />
  </svg>
)

const Memo = memo(SvgPhone)
export default Memo
