import * as React from 'react'
import { memo } from 'react'

const SvgEmail = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 16.22"
  >
    <path d="M.85 16.22a.852.852 0 0 1-.85-.849V.871a.775.775 0 0 1 0-.106.011.011 0 0 0 0-.006.029.029 0 0 0 0-.009.853.853 0 0 1 .76-.745.794.794 0 0 1 .1 0h18.259a.8.8 0 0 1 .106 0 .853.853 0 0 1 .765.846v14.521a.92.92 0 0 1-.893.849Zm.807-1.656h16.6V2.944L10.572 10.7a.84.84 0 0 1-.6.256.84.84 0 0 1-.595-.256L1.649 2.865Zm8.322-5.647 7.189-7.261H2.824Z" />
  </svg>
)

const Memo = memo(SvgEmail)
export default Memo
