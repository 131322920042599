import * as React from 'react'

function SvgTrain() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 27.985 28.002"
    >
      <path d="M26.442 27.832l-2.77-1.831H4.3l-2.758 1.831a.973.973 0 01-.55.17 1.011 1.011 0 01-.823-.444 1 1 0 01.273-1.387L6.99 21.82a3 3 0 01-2-2.819v-13a3 3 0 013-3h5v-1h-2a1 1 0 01-1-1 1 1 0 011-1h6a1 1 0 011 1 1 1 0 01-1 1h-2v1h5a3 3 0 013 3v13a2.994 2.994 0 01-2 2.819l6.551 4.351a1 1 0 01.443.821 1 1 0 01-.992 1.009.966.966 0 01-.55-.169zm-19.12-3.831h13.32l-3-2h-7.32zm-.331-18v13a1 1 0 001 1h12a1 1 0 001-1v-13a1 1 0 00-1-1h-12a1 1 0 00-.999 1zm5 11a1 1 0 01-1-1 1 1 0 011-1h4a1 1 0 011 1 1 1 0 01-1 1zm-1.22-4a1.781 1.781 0 01-1.78-1.781V8.781A1.779 1.779 0 0110.771 7h6.44a1.781 1.781 0 011.78 1.781v2.439a1.783 1.783 0 01-1.78 1.781zm.22-2h6v-2h-6z" />
    </svg>
  )
}

const MemoSvgTrain = React.memo(SvgTrain)
export default MemoSvgTrain
