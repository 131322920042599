import * as React from 'react'

function SvgExpand() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
    >
      <path d="M1 0h6v2H2v5H0V1a1 1 0 011-1zm18 0h-6v2h5v5h2V1a1 1 0 00-1-1zM2 13H0v6a1 1 0 001 1h6v-2H2zm16 5h-5v2h6a1 1 0 001-1v-6h-2z" />
    </svg>
  )
}

const MemoSvgExpand = React.memo(SvgExpand)
export default MemoSvgExpand
