import * as React from 'react'

function SvgUser() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
    >
      <path d="M.999 20a1 1 0 01-1-1 7 7 0 017-7h6a7 7 0 017 7 1 1 0 01-1 1zm16.9-2a5 5 0 00-4.9-4h-6a5 5 0 00-4.9 4zM4.499 5.5a5.5 5.5 0 015.5-5.5 5.5 5.5 0 015.5 5.5 5.51 5.51 0 01-5.5 5.5 5.5 5.5 0 01-5.5-5.5zm2 0a3.5 3.5 0 003.5 3.5 3.5 3.5 0 003.5-3.5 3.5 3.5 0 00-3.5-3.5 3.5 3.5 0 00-3.5 3.5z" />
    </svg>
  )
}

const MemoSvgUser = React.memo(SvgUser)
export default MemoSvgUser
