import * as React from 'react'

function SvgChevronDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 14.811 10.233"
    >
      <path d="M1.407 1.424c1.511 1.5 5.933 5.981 5.933 5.981l6.067-5.981" />
    </svg>
  )
}

const MemoSvgChevronDown = React.memo(SvgChevronDown)
export default MemoSvgChevronDown
