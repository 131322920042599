import * as React from 'react'

function SvgMq() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20.496 20.401"
    >
      <path d="M.913.202a.714.714 0 00-.705.817v5.626a.714.714 0 001.428 0V2.647L6.129 7.14a.714.714 0 101.009-1.01L2.644 1.636h4a.714.714 0 100-1.428H1.007A.632.632 0 00.913.202zm18.559 0a.647.647 0 00-.079.007h-5.63a.714.714 0 100 1.428h4L13.268 6.13a.714.714 0 001.01 1.01l4.494-4.494v4a.714.714 0 001.428 0V1.009a.732.732 0 00.006-.094.714.714 0 00-.714-.713h-.02zM.91 13.051a.714.714 0 00-.7.714v5.788a.093.093 0 010 .012c0 .021.005.039.008.058v.024a.717.717 0 00.068.188.158.158 0 00.011.019l.029.049.011.016a.724.724 0 00.138.148l.006.006a.71.71 0 00.42.129h5.74a.714.714 0 100-1.428h-4l4.493-4.494a.714.714 0 00-.5-1.225h-.02a.713.713 0 00-.491.215l-4.489 4.493v-4a.714.714 0 00-.714-.714H.91zm18.566 0a.714.714 0 00-.7.714v4l-4.494-4.494a.711.711 0 00-.512-.216.714.714 0 00-.5 1.225l4.493 4.494h-4a.714.714 0 000 1.428h5.722a.709.709 0 00.216-.034h.006a.653.653 0 00.071-.026h.008l.064-.031a.732.732 0 00.1-.064l.028-.023a.572.572 0 00.044-.042.669.669 0 00.084-.107.655.655 0 00.033-.057.662.662 0 00.049-.127v-.01a.689.689 0 00.013-.179.111.111 0 000-.016v-5.72a.714.714 0 00-.714-.714h-.011z" />
    </svg>
  )
}

const MemoSvgMq = React.memo(SvgMq)
export default MemoSvgMq
