import * as React from 'react'
import { memo } from 'react'

const SvgDownload = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 21.333 16"
  >
    <path d="M2.666 16a2.666 2.666 0 0 1-2.667-2.667V8a.888.888 0 0 1 .889-.889.89.89 0 0 1 .89.889v5.333a.887.887 0 0 0 .888.889h16a.888.888 0 0 0 .889-.889V8a.889.889 0 0 1 .889-.889.889.889 0 0 1 .889.889v5.333A2.666 2.666 0 0 1 18.667 16Zm7.359-5.606L5.581 5.772a.89.89 0 0 1 .025-1.257.888.888 0 0 1 1.256.025l2.916 3.032V.889A.889.889 0 0 1 10.667 0a.889.889 0 0 1 .889.889v6.682l2.914-3.032a.889.889 0 0 1 1.257-.025.889.889 0 0 1 .025 1.257l-4.445 4.622a.885.885 0 0 1-.64.273.891.891 0 0 1-.642-.272Z" />
  </svg>
)

const Memo = memo(SvgDownload)
export default Memo
