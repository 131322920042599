import * as React from 'react'

function SvgPin() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16.329 20"
    >
      <path d="M7.352 19.5l-1.781-3.562A8.18 8.18 0 01.108 6.87 8.182 8.182 0 018.166.003a8.182 8.182 0 018.057 6.867 8.181 8.181 0 01-5.463 9.068L8.979 19.5a.908.908 0 01-.813.5.908.908 0 01-.814-.5zM1.861 7.316a6.363 6.363 0 004.594 6.993.908.908 0 01.569.469l1.142 2.283 1.141-2.283a.912.912 0 01.569-.469 6.363 6.363 0 004.594-6.993 6.362 6.362 0 00-6.3-5.5 6.363 6.363 0 00-6.31 5.497zm2.668.869a3.636 3.636 0 013.637-3.636 3.637 3.637 0 013.636 3.636 3.637 3.637 0 01-3.636 3.636 3.637 3.637 0 01-3.638-3.639zm1.819 0a1.818 1.818 0 001.818 1.818 1.819 1.819 0 001.818-1.818 1.818 1.818 0 00-1.818-1.818 1.817 1.817 0 00-1.819 1.815z" />
    </svg>
  )
}

const MemoSvgPin = React.memo(SvgPin)
export default MemoSvgPin
