import * as React from 'react'

function SvgCar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 30 30"
    >
      <path d="M7.522.004a1.069 1.069 0 00-1.047.85l-2.04 10.21a5.2 5.2 0 00-4.4 5l-.031 8.573a1.071 1.071 0 001.066 1.076h1.105v1.067A3.231 3.231 0 005.382 30a3.233 3.233 0 003.209-3.22v-1.067h12.845v1.067A3.233 3.233 0 0024.645 30a3.238 3.238 0 003.215-3.22v-1.067h1.066A1.071 1.071 0 0030 24.637v-8.565a5.377 5.377 0 00-4.463-5.282L23.553.854A1.071 1.071 0 0022.502 0zM8.4 2.147h13.226l1.718 8.565H6.688zm16.245 10.704a3.191 3.191 0 013.215 3.22v7.5H2.149l.025-7.5a3.183 3.183 0 013.2-3.22zm-17.123 2.1a3.229 3.229 0 00-3.207 3.219 3.224 3.224 0 003.207 3.212 3.225 3.225 0 003.209-3.212 3.231 3.231 0 00-3.209-3.222zm13.914 0a3.229 3.229 0 00-3.207 3.219 3.224 3.224 0 003.207 3.212 3.225 3.225 0 003.209-3.212 3.231 3.231 0 00-3.209-3.222zm-13.914 2.14a1.057 1.057 0 011.068 1.076 1.052 1.052 0 01-1.068 1.069 1.057 1.057 0 01-1.074-1.069 1.062 1.062 0 011.074-1.076zm13.914 0a1.055 1.055 0 011.066 1.076 1.05 1.05 0 01-1.066 1.069 1.057 1.057 0 01-1.074-1.069 1.062 1.062 0 011.074-1.076zM4.315 25.712h2.132v1.067a1.046 1.046 0 01-1.066 1.076 1.046 1.046 0 01-1.066-1.076zm19.261 0h2.143v1.067a1.053 1.053 0 01-1.074 1.076 1.048 1.048 0 01-1.068-1.076z" />
    </svg>
  )
}

const MemoSvgCar = React.memo(SvgCar)
export default MemoSvgCar
